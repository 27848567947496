  #board {
    position: relative;
    width: 100%; /* Adjust as necessary */
    height: 100%; /* Adjust as necessary */
    margin: 0 auto;
    overflow: hidden;
    background: rgb(27, 23, 37);
  }

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 0.8svh solid #f3f3f3; /* Light grey */
    border-top: 0.8svh solid #C4C4C4;
    border-radius: 50%;
    width: 5.4svh;
    height: 5.4svh;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .onboarding {
    background-color: #60656F;
    opacity: 0.93;
    width: 42.7svh;
    height: 57svh; /* was 62hv for full view */
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -5%);
    border-radius: 1.7svh;
    transition: transform 0.05s linear, opacity 0.01s ease-in-out;;
  }

  .onboarding img {
    position: absolute;
    width: 17.5svh;
    height: 10.78svh;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    pointer-events: none;
  }

  #onboardingSkipImg {
    top: 31.3svh; /* was 36.3svh*/
  }

  #onboardingUpvoteImg {
    top: 5.3svh;
  }

  .onboarding-text {
    position: absolute;
    width: 29.5svh;
    height: 8.6svh;
    color: white;
    font-family: 'Inter';
    font-weight: 400;
    text-align: center;
    font-size: 3.8svh;
    left: 50%;
    transform: translateX(-50%);
    /* padding-top: 50%; */
  }

  .onboarding-text-upvote {
    top: 18.57svh
  }

  .onboarding-text-skip {
    top: 43.8svh /* was 48.8svh */
  }

  #loadingImage {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 17.8svh;
    height: 16.3svh;
    transform: translate(-50%, -50%);
  }

  /* img {
    pointer-events: none;
  } */

  #loadingImageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(27, 23, 37);
    z-index: 1;
  }

  .card {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 42.7svh;
    height: 57svh; /* was 62hv for full view */
    background-size: 42.7svh;
    background-position: top;
    background-repeat: no-repeat;
    transform: translate(-50%, 0%);
    transition: transform 0.05s linear, opacity 0.5s ease-in-out;
    box-shadow: 0px -6px 3px -2px rgba(0, 0, 0, 0.6);
    border-radius: 1.7svh;
    background-color: #38353F;
  }

  .hidden {
    display: none !important;
  }

  .overlay {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -5%) scale(1);
    width: 42.7svh;
    height: 57svh; /* was 62hv for full view */
    border-radius: 1.7svh;
    background-color: rgb(36, 155, 240); /* overlay color */
    opacity: 0;
    transition: transform 0.05s linear, opacity 0.01s ease-in-out;
    pointer-events: none; /* Disable pointer events on the overlay */
  }

  .overlay-text {
    position: absolute;
    top: 47.6svh; /*was 50.6svh for full view */
    left: 50%;
    transform: translateX(-50%);
    width: 22.6svh;
    height: 3.9svh;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 3.8svh;
    color: white;
    /* justify-content: center; */
    text-align: center;
  }

  #squareContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 42.7svh;
    height: 42.7svh;
    overflow: hidden;
  }

  #volumeButton {
    height: 3.2svh; 
    cursor: pointer;
    position: absolute;
    left: 1.7svh;
    bottom: 1.7svh;
  }

  .unmuted {
    width: 5svh;
  }

  .muted {
    width: 4.74svh;
  }

  .background-media {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image/video covers the entire container */
    transform: translate(-50%, -50%);
    border-top-left-radius: 1.7svh;
    border-top-right-radius: 1.7svh;
    z-index: -1;
    border: none;
    outline: none;
  }

  .last-card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 84.7svh;
  }

  #cardTitle {
    color: white;
    font-size: 2.3svh;
    font-family: 'Inter';
    position: absolute;
    top: 45svh;
    left: 1.7svh;
    /* width: 27.8svh; */
    right: 1.7svh;
    height: 6.1svh;
    min-height: 57px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 900;
    display: -webkit-box; /* Use WebKit box model */
    -webkit-line-clamp: 2; /* Limit the text to 2 lines */
    -webkit-box-orient: vertical; /* Vertical orientation */
    vertical-align: middle;
  }

  #cardAuthor {
    color: white;
    font-size: 1.7svh;
    font-family: 'Inter';
    position: absolute;
    left: 1.7svh;
    top: 52svh;
    /* width: 37.5svh; */
    right: 1.7svh;
    height: 2.5svh;
    min-height: 24px;
    vertical-align: middle;
  }

  #skipButton {
    position: absolute;
    /* background-image: url('static/skipButton.svg');
    background-repeat: no-repeat;
    background-size: 4.2svh;
    background-position: center; */
    background-color: white;
    border-radius: 50%;
    width: 8.6svh;
    height: 8.6svh;
    left: 50%;
    transform: translateX(-17.9svh);
    bottom: 9svh;
    cursor: pointer;
    transition: scale 0.05s ease-in-out;
  }

  #skipButton:hover{
    background-color: rgb(237, 237, 237);
  }
  #skipButton:active{
    scale: 0.97;
  }

  #upvoteButton {
    position: absolute;
    /* background-image: url('static/upvoteButton.svg');
    background-repeat: no-repeat;
    background-size: 3.5svh;
    background-position: center; */
    background-color: #41D3BD;
    border-radius: 50%;
    width: 8.6svh;
    height: 8.6svh;
    left: 50%;
    transform: translateX(7.9svh);
    bottom: 9svh;
    cursor: pointer;
    transition: scale 0.05s ease-in-out;
  }

  #upvoteButton:hover{
    background-color: #30c6af;
  }
  #upvoteButton:active{
    scale: 0.95;
  }

  #upvoteButtonText, #skipButtonText {
    position: absolute;
    top: 10.4svh;
    width: 8.6svh;
    height: 3.8svh;
    color: white;
    font-family: 'Inter';
    font-weight: 900;
    text-align: center;
    font-size: 1.7svh;
    padding-top: 50%;
    transform: translateY(-50%);
  }

  .inactive-button {
    background-color: #C4C4C4 !important;
    scale: 1 !important;
  }

  #skipButton img, #upvoteButton img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  #skipButton img {
    width: 3.67svh;
    height: 2.8svh;
  }

  #upvoteButton img {
    width: 3svh;
    height: 2.8svh;
  }

  .card:nth-child(5) {
    transform: translate(-50%, -5%) scale(1);
  }

  .card:nth-child(4) {
    transform: translate(-50%, -10.5%) scale(0.93);
  }

  .card:nth-child(3) {
    transform: translate(-50%, -15.5%) scale(0.86);
  }

  .card:nth-child(2) {
    transform: translate(-50%, -21.5%) scale(0.77);
  }

  .card:nth-child(1) {
    transform: translate(-50%, -26.2%) scale(0.7);
  }

  #endScreen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: -1;
  }

  #ufoEmoji {
    position: absolute;
    font-size: 13.8svh;
    top: 8svh;
  }

  #endTitle {
    position: absolute;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 3.5svh;
    width: 24svh;
    color: white;
    text-align: center;
    top: 27.6svh
  }

  #endInfo {
    position: absolute;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 2.6svh;
    width: 34.3svh;
    color: white;
    text-align: center;
    top: 43.6svh;
  }

  #endExplanation {
    position: absolute;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1.7svh;
    width: 28.9svh;
    color: #828282;
    text-align: center;
    top: 69.4svh;
  }

  #endButton {
    position: absolute;
    background-color: white;
    border-radius: 1.29svh;
    width: 31.3svh;
    height: 6.5svh;
    color: #00100B;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 2.6svh;
    text-align: center;
    align-content: center;
    cursor: pointer;
    top: 59.3svh;
    text-decoration: none;
  }

  #endButton:hover {
    background-color: rgb(234, 234, 234);
  }

  #endButton:active {
    scale: 0.97;
  }

  #endLink {
    position: absolute;
    text-align: center;
    width: 20.1svh;
    height: 3.88svh;
    color: white;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 1.7svh;
    cursor: pointer;
    top: 91.3svh;
  }

  #xLogo {
    height: 2.75svh;
    bottom: -0.8svh;
    position: relative;
    margin-left: 0.5svh;
  }

  #endLink img::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px; /* Adjust to position underline correctly */
    left: 0;
    width: 100%;
    height: 2px; /* Adjust underline thickness */
    background-color: currentColor; /* Matches the text color */
  }

  #endLink:active {
    scale: 0.97;
  }

  .non-highlightable {
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
